import { FaMapMarkerAlt } from 'react-icons/fa';
import { MarkerType } from '../interface';

function Marker({text}: MarkerType): React.ReactElement {
	return (
		<div className="marker">
			<FaMapMarkerAlt />
			<div className="marker__text">{text}</div>
		</div>
	);
}

export default Marker;

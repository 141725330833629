import ContactInfo from './contact-info';
import MyLocation from '../my-location/';
import Thanks from './thanks';

function Contact() {
	return (
		<section id="contact" className="sections">
			<ContactInfo />
			<MyLocation />
			<Thanks />
		</section>
	);
}

export default Contact;

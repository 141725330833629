import useData from '../hooks/useData';

import {
	FaLinkedinIn,
	FaGithub,
	FaInstagram,
} from 'react-icons/fa';
import { LinksType } from '../interface';

function SocialIcon({type}: {type: string}): React.ReactElement {
	switch (type) {
		case 'github':
			return <FaGithub />;
		case 'instagram':
			return <FaInstagram />;
		default:
			return <FaLinkedinIn />;
	}
}

function Social() {
	const content = useData('social');

	return (
		<div id="social">
			<p className="social__text">Some other ways to reach me...</p>
			{content.map((entry: LinksType, index: number) => {
				const {url, type} = entry;
				return (
					<a key={index} href={url} target="_blank" rel="noreferrer">
						<SocialIcon type={type} />
					</a>
				);
			})}
		</div>
	);
}

export default Social;
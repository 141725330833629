import useData from '../hooks/useData';

import Card from './card';

import { CardType } from '../interface';

function PersonalProjects(): React.ReactElement {
	const content = useData('personalProjects');

	return (
		<section id="personal-projects">
			<div className="container">
				<div className="row">
					{content.map((entry: CardType , index: number) => <Card key={index} {...entry} /> )}
				</div>
			</div>
		</section>
	);
}

export default PersonalProjects;

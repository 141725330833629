import {
	BrowserRouter as Router,
	Routes,
	Route
} from 'react-router-dom';

import './styles.scss';

import Home from './components/home';
import NotFound from './components/errors/not-found';

function App(): React.ReactElement {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Router>
	);
}

export default App;

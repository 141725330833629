interface VolunteerHistoryType {
	date: string,
	position: string,
}

function VolunteerHistory({
	date,
	position,
}: VolunteerHistoryType): React.ReactElement {
	return (
		<>
			{date}
			{' '}
			<em>{position}</em><br />
		</>
	);
}

export default VolunteerHistory;

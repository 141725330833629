import Social from './social';

function ContactInfo() {
	return (
		<div id="contact-info">
			<div className="container">
				<h2 className="section-heading divider-after">Say hi</h2>
				<div className="contact-info__email">
					<a href="mailto:me@robinjulius.com">me@robinjulius.com</a>
				</div>
				<Social />
			</div>
		</div>
	);
}

export default ContactInfo;
import { FaLongArrowAltDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ScrollToType } from '../interface';

function Hero({onNavClick}: ScrollToType): React.ReactElement {
	return (
		<section id="top" className="sections">
			<div>
				<h1>Robin Roy Julius</h1>
				
				<div className="specialty">
					<h2>UX / UI</h2>
					<h2>Front End</h2>
					<h2>Minimalist</h2>
				</div>

				<p className="links divider divider-before">
					<Link
						to={{pathname: '/', hash: '#cv'}}
						onClick={event => onNavClick(event, '#cv')}
					>
						Where I have worked
					</Link>
					<Link
						to={{pathname: '/', hash: '#projects'}}
						onClick={event => onNavClick(event, '#projects')}
					>
						What I have worked on
					</Link>
					<Link
						to={{hash: '#skills'}}
						onClick={event => onNavClick(event, '#skills')}
					>
						What I can do
					</Link>
					<Link
						to={{hash: '#contact'}}
						onClick={event => onNavClick(event, '#contact')}
					>
						Say hi
					</Link>
				</p>
			</div>

			<div className="scroll-for-more">
				<Link
					to={{hash: '#cv'}}
					onClick={event => onNavClick(event, '#cv')}
				>
					<FaLongArrowAltDown /> Would you like to know more?
				</Link>
			</div>
		</section>
	);
}

export default Hero;

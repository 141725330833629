/**
 * Nav.
 * @type {Array}
 */
export const nav = [
	{ title: 'Top', hash: 'top' },
	{ title: 'Where I have worked', hash: 'cv' },
	{ title: 'What I have worked on', hash: 'projects' },
	{ title: 'What I can do', hash: 'skills' },
	{ title: 'Say hi', hash: 'contact' },
];

/**
 * Major Projects.
 * @type {Array}
 */
export const majorProjects = [
	{
		id:    'last-cigarette-app',
		image: {
			url:    '/img/last-cigarette-app-iphone6s-angled.png',
			styles: [ 'col-sm-6' ],
		},
		logo:  {
			url:    '/img/last-cigarette-app-icon.png',
			styles: [ 'project-item__logo--app-icon' ],
		},
		title:       'Last Cigarette iOS app',
		description: {
			text:   'A minimalist counter to track the time you last quit smoking. It shows information such as the duration, money saved and cigarettes not smoked.',
			styles: [ 'col-sm-6' ],
		},
		links:       [
			{
				url:  'https://itunes.apple.com/us/app/last-cigarette/id1088134074',
				type: 'apple',
			},
			{
				url:  'https://github.com/exoduz/last-cigarette',
				type: 'github',
			},
		],
	},
	{
		id:    'collectstor-app',
		image: {
			url:    '/img/collectstor-app-iphone6s-45degrees.png',
			styles: [ 'col-sm-6', 'order-sm-2' ],
		},
		logo:  {
			url:    '/img/collectstor-app-icon.png',
			styles: [ 'project-item__logo--app-icon' ],
		},
		title:       'Collectstor iOS app',
		description: {
			text:   'The Collectstor iPhone app is also available.<br />Now you can showcase and share collections on the go.',
			styles: [ 'col-sm-6' ],
		},
	},
	{
		id:    'collectstor',
		image: {
			url:    '/img/collectstor-website-macbook-silver.png',
			styles: [ 'col-sm-5' ],
		},
		logo:  {
			url:    '/img/collectstor-logo-inline.png',
			styles: [],
		},
		title:       'Collectstor Website',
		description: {
			text:   'Collectstor is a platform for collectors of all things to socialize, explore, and showcase their own collections.',
			styles: [ 'col-sm-7' ],
		},
	},
];

/**
 * Personal Projects.
 * @type {Array}
 */
export const personalProjects = [
	{
		image:          '/img/robinjulius-2016-project-icon.png',
		title:          'Robin\'s Website (2020)',
		description:    'Took some time to update React and recode the website. The biggest update is using React Hooks.',
		codingLanguage: [
			'ReactJS',
			'SCSS',
		],
	},
	{
		image:          '/img/robinjulius-2016-project-icon.png',
		title:          'Robin\'s Website (2016)',
		description:    'Taking a minimalist and more open approach, I decided to rewrite my website to be a lot cleaner.',
		codingLanguage: [
			'ReactJS',
			'jQuery',
		],
		links: [
			{
				url:  'https://github.com/exoduz/robin-2016-react',
				type: 'github',
			},
		],
	},
	{
		image:          '/img/robinjulius-2015-project-icon.png',
		title:          'Robin\'s Website (2015)',
		description:    'My first ReactJS project, I decided to take this opportunity to change my personal site from WordPress to ReactJS.',
		codingLanguage: [
			'ReactJS',
			'jQuery',
		],
		links: [
			{
				url:  'https://work.robinjulius.com/robin-2015',
				type: 'website',
			},
			{
				url:  'https://github.com/exoduz/robin-react',
				type: 'github',
			}
		],
	},
	{
		image:          '/img/jerryyeoh-2016-project-icon.png',
		title:          'Jerry\'s Portfolio Website',
		description:    'Originally this was written in PHP, but I was on a roll so I decided to also rewrite it in ReactJS just for kicks.',
		codingLanguage: [
			'ReactJS',
			'jQuery',
		],
		links: [
			{
				url:  'https://work.robinjulius.com/jerry-react',
				type: 'website',
			},
			{
				url:  'https://github.com/exoduz/jerry-react',
				type: 'github',
			},
		],
	},
];

/**
 * CV Link.
 * @type {String}
 */
export const cv = 'https://docs.google.com/document/d/1Ze2ZQqapNyFA8AQHA2orCh5cu7UUly4nFcMoFgx9Dyw/edit';

/**
 * Resume data.
 * @type {Array}
 */
export const resume = [
	{
		company: 'Meta',
		role: 'Front End Engineer',
		location: 'San Francisco, USA',
		description: [],
		date: 'December 2023 - Present'
	},
	{
		company: 'Proofpoint',
		role: 'Staff Software Engineer (Front End)',
		location: 'San Francisco, USA',
		description: [
			'Work within the team and together with Product Managers and Designers to release SER (Secure Email Relay) a new product extending Proofpoint’s product suite by coding new features and updates.',
			'Implement UX/UI updates to improve accessibility, usability and improve overall ease of use for all customers.',
			'Update Proofpoint’s internal component library improving accessibility, usability and ease of use for users.',
			'Champion the adoption of TypeScript for current and future projects, by setting up proof of concepts with the Micro Front End architecture, running and leading the design review process in preparation for adoption.',
			'Member of committee setting standards for Proofpoint\'s Front End architecture, Component Libraries and Micro Front End architecture.',
		],
		date: 'January 2023 - December 2023'
	},
	{
		company: 'Meta / Facebook',
		role: 'Front End / UI Engineer',
		location: 'San Francisco, USA',
		description: [
			'Improved UX/UI flows in React by reducing friction, unneeded visual noise, and accessibility for Daiquery and Bento, an internal data visualization tool used by 50,000 staff resulting in 30% satisfaction improvement.',
			'Collaborated closely with design and PMs to implement enhancements for accessibility and support for all internal differently abled staff members.',
			'Architect and developed using React on security and privacy features such as collating signals to reduce multiple requests within the internal tools to protect user data and information.',
			'Championed product consistency to standardize data visualization tools across the organization. This reduced onboarding time for new users by 10 mins and added a familiarity through the family of internal tools.',
			'Led improvement to reduce noise within messaging and notices on multiple internal data visualization tools.',
			'Added support for smaller screen resolution for tools to provide better support for different devices allowing 1,000 more users daily to be able to use the product.',
		],
		date: 'October 2021 - January 2023'
	},
	{
		company: 'TechCrunch',
		role: 'Lead Software Engineer / Engineering Manager',
		location: 'San Francisco, USA',
		description: [
			'Redesigned and relaunched TechCrunch website in February 2018 using a WordPress backend with a decoupled React/Redux frontend.',
			'Built and launched subscription, integrating with third party payment experience platform Piano, completed Extra Crunch (renamed TechCrunch+), a subscribers’ paywall in February 2019 (redesigned in July 2020) enabling a new revenue stream for TechCrunch.',
			'Led a team of engineers in developing a new Live and Video on Demand player for TechCrunch Disrupt Live 2020. Includes a new livestream and video player with gated access levels and live schedule. Resulting in 18,000 sign ups during Disrupt.',
			'Redesign and implement a new ad tech platform, including Native Ads to improve ad impressions and revenue from 60% to 76%.',
			'Re-architect and fix reliability issues for login and payments page, resulting in reducing load time from over 25 seconds to under 5 seconds.',
			'Re-architect new commenting system with Single Sign-On (SSO) and reduced spam by 60%.',
			'Worked as a lead and with Editors, implemented a new newsletter delivery system that allowed a wider variety of mail clients and devices, and increased click metrics by 30%.',
		],
		date: 'October 2016 - October 2021'
	},
	{
		company: 'Collectstor',
		role: 'Founder and Full Stack Developer',
		location: 'Tokyo, Japan and Perth, Australia',
		description: [
			'Coded website which included user registration, profile information; news feed and upload feature using a LAMP stack using CakePHP as a framework.',
			'Created iOS App in Swift, and implemented a RESTful API for the app.',
			'Honed entrepreneurial skills by marketing the business at events and to shops throughout Perth and the US.',
			'Managed social media, growth, online marketing and presence to drive usage.',
		],
		date: 'May 2013 - October 2016'
	},
	{
		company: 'Australian Embassy, Tokyo',
		role: 'Electronic Media Administrator',
		location: 'Tokyo, Japan and Perth, Australia',
		description: [
			'Built websites for the Public Diplomacy section, focused on promoting Australia to Japan through arts and culture, education and AusTrade.',
			'Developed in-house Content Management Systems (CMS) including an event management and booking CMS; an invitation, RSVP and check-in system for the Embassy’s events.',
			'Internationalized and updated all Embassy and attached agencies’ websites to be W3C and HTML5 compliant, also converting all pages from static to dynamic (LAMP stack) and fully responsive.',
			'Other duties included Linux web server maintenance and official photographer.',
		],
		date: 'July 2008 - April 2013'
	},
	{
		company: 'IT Vision',
		role: 'Web and Software Developer',
		location: 'Perth, Australia',
		description: [
			'Successful integration of a web component using HTML, XSL and C# into their in-house CRM system which uses IBM UniVerse and Java.',
			'Give a web developer\'s perspective to the company\'s CRM, which help improve the fluency of the project timeline.',
		],
		date: 'October 2006 - March 2007'
	},
	{
		company: 'HomeTrader',
		role: 'Web Developer and Project Manager',
		location: 'Perth, Australia',
		description: [
			'Manage and code newsletters, web related content and new projects, as well as an intranet system for web projects in ASP.NET and MS SQL Server.',
		],
		date: 'March 2006 - October 2006'
	},
	{
		company: 'Itomic',
		role: 'Full Stack Web Developer',
		location: 'Perth, Australia',
		description: [
			'Work on the clients\' needs analysis, design, implementation and timely deployment of projects.',
			'Project manager and team lead on several projects.',
			'Website projects done in PHP, MySQL and JavaScript. Maintain LAMP server. ',
			'Code and implement the first customisable online shopping cart in PHP.',
		],
		date: 'January 2005 - February 2006'
	},
	{
		company: 'Omegatrend International',
		role: 'HTML and Javascript Programmer (Contract Position)',
		location: 'Perth, Australia',
		description: [
			'Code HTML and JavaScript pages for their Global Expansion Program into Hong Kong, the Philippines, the USA, the UK, South Africa and Brunei.',
		],
		date: 'December 2004'
	},
	{
		company: 'Precise IT Solutions',
		role: 'Web Developer and Program Analyst',
		location: 'Perth, Australia',
		description: [
			'Analyse, design, and implement websites for clients in PHP and ASP, based on extensive research of customer needs and specifications. Also manage end-to-end server setup, deployment and support.',
			'Propose and led effort to create a successful online shop, transitioning from IT only to a complete IT and web solution.',
		],
		date: 'January 2002 - January 2005'
	},
	{
		company: 'Speedworks Motorsports',
		role: 'Database Administrator, Sales Rep and Pit Crew',
		location: 'Perth, Australia',
		description: [
			'Successfully create, implement and maintain a stock and products database.',
		],
		date: 'January 1999 - January 2002'
	}
];

/**
 * Programming data.
 * @type {Object}
 */
export const programming = {
	programming: [
		{ name: 'Javascript', styles: 'js', level: 90 },
		{ name: 'PHP', styles: 'php', level: 90 },
		{ name: 'HTML5', styles: 'html', level: 98 },
		{ name: 'CSS', styles: 'css', level: 98 },
		{ name: 'C#', styles: 'csharp', level: 60 }
	],
	frameworks: [
		{ name: 'ReactJS', styles: 'react-js', level: 95 },
		{ name: 'CakePHP', styles: 'cakephp', level: 95 },
		{ name: 'WordPress', styles: 'wordpress', level: 85 },
		{ name: '.NET', styles: 'dotnet', level: 60 }
	],
	database: [
		{ name: 'MySQL', styles: 'mysql', level: 85 },
		{ name: 'MS SQL', styles: 'sqlserver', level: 60 }
	],
	webserver: [
		{ name: 'Apache', styles: 'apache', level: 85 },
		{ name: 'Nginx', styles: 'nginx', level: 85 },
		{ name: 'IIS', styles: 'iis', level: 75 }
	],
};

/**
 * Languages data.
 * @type {Array}
 */
export const languages = [
	{ name: 'English', proficiency: 'Native', styles: 'english', level: 98 },
	{ name: 'Indonesian', proficiency: 'Native', styles: 'indonesian', level: 98 },
	{ name: 'Japanese', proficiency: 'Business', styles: 'japanese', level: 85 },
	{ name: 'Mandarin', proficiency: 'Conversational', styles: 'mandarin', level: 70 },
	{ name: 'Cantonese', proficiency: 'Conversational', styles: 'cantonese', level: 50 }
];

/**
 * Software data.
 * @type {Object}
 */
export const software = {
	os: [
		{ name: 'OSX', styles: 'osx', level: 98 },
		{ name: 'Windows', styles: 'windows', level: 98 },
		{ name: 'Linux', styles: 'linux', level: 75 }
	],
	software: [
		{ name: 'MS Office', styles: 'office', level: 98 },
		{ name: 'Sublime', styles: 'sublime', level: 98 },
		{ name: 'Adobe Suite', styles: 'adobe', level: 70 },
		{ name: 'Visual Studio', styles: 'visualstudio', level: 70 },
	],
};

/**
 * Volunteer history data.
 * @type {Array}
 */
export const volunteerHistory = [
	{
		event:       'TechCrunch Disrupt',
		description: [
			{
				date:     'Oct 2020',
				position: 'Include Round Table (Diversity and Inclusion) Panelist / Moderator',
			},
		],
	},
	{
		event:       'TEDxPerth',
		description: [
			{
				date:     'Nov 2014',
				position: '',
			},
			{
				date:     'Nov 2013',
				position: '',
			},
		],
	},
	{
		event:       'Startup Weekend',
		description: [
			{
				date:     'Apr 2015',
				position: 'Mentor',
			},
			{
				date:     'Nov 2014',
				position: 'Organiser',
			},
			{
				date:     'Apr 2014',
				position: 'Mentor',
			},
			{
				date:     'Nov 2013',
				position: 'Mentor',
			},
			{
				date:     'Apr 2013',
				position: 'Mentor',
			},
		],
	},
];

/**
 * Certifications data.
 */
export const certifications = [
	{
		school:      'Meros Language School',
		description: 'Completion of Japanese language course'
	},
	{
		school:      'Yuh Mei Chinese School',
		description: 'Completion of Chinese language course'
	},
];

/**
 * My Location data.
 * @type {Object}
 */
export const myLocation = {
	center:  { lat: 37.8313, lng: -122.2852 }, //Emeryville
	current: { lat: 37.8700, lng: -122.2927 }, //Berkeley
	key:     'AIzaSyBGIscxtklKxt54v1-W-lsxI5_TuwGr75A',
	options: {
		panControl:             false,
		draggable:              false,
		mapTypeControl:         false,
		scrollwheel:            false,
		disableDoubleClickZoom: true
	},
	styles: {
		width:  '100%',
		height: '400px',
	},
	zoom: 11,
};

/**
 * Social data.
 * @type {Array}
 */
export const social = [
	{
		url: 'https://linkedin.com/in/robinjulius',
		type: 'linkedin',
	},
	{
		url: 'https://github.com/exoduz',
		type: 'github',
	},
	{
		url: 'https://instagram.com/robinroyjulius',
		type: 'instagram',
	},
];

/**
 * Thanks data.
 * @type {Array}
 */
export const thanks = [
	'Thanks for dropping by...',
	'またね。。。',
	'Sampai ketemu lagi...',
	'後會有期。。。',
];

const data = {
	nav,
	majorProjects,
	personalProjects,
	cv,
	resume,
	programming,
	languages,
	software,
	volunteerHistory,
	certifications,
	myLocation,
	social,
	thanks,
};

export default data;

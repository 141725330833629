import Resume from './resume';

function CV(): React.ReactElement {
	return (
		<div id="cv" className="sections">
			<div className="container">
				<h2 className="section-heading divider-after">Where I have worked</h2>
				<Resume />
			</div>
		</div>
	);
}

export default CV;

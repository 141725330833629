import classNames from 'classnames';

import {
	FaApple,
	FaExternalLinkAlt,
	FaGithub,
} from 'react-icons/fa';
import { ProjectItemType } from '../interface';

function ProjectItem({
	id,
	image,
	logo,
	title,
	description,
	links,
}: ProjectItemType): React.ReactElement {
	const itemClasses = classNames(
		'project-item',
		'row',
		'align-items-center',
		{[ `project-item--${id}` ]: true},
	);

	const imageClasses = classNames(
		'project-item__image',
		image.styles,
	);

	const descriptionClasses = classNames(
		'project-item__text',
		'divider-after',
		description.styles,
	);

	const logoClasses = classNames(
		'project-item__logo',
		'img-responsive',
		logo.styles,
	);

	return (
		<div className="container">
			<div id={`${id}`} className={itemClasses}>
				<div className={imageClasses}>
					<img src={`${process.env.PUBLIC_URL}${image.url}`} alt={title} />
				</div>

				<div className={descriptionClasses}>
					<img src={
						`${process.env.PUBLIC_URL}${logo.url}`} alt={`${title} logo`} className={logoClasses} />

					<p dangerouslySetInnerHTML={{__html: description.text}} />
					
					{links && <p>
						{links.map((link, index: number): React.ReactElement => {
								let linkIcon = <FaExternalLinkAlt />;
								let linkText = 'Visit website and start showcasing';

								if (link.type === 'apple') {
									linkIcon = <FaApple />;
									linkText = 'Download from the App Store';
								} else if (link.type === 'github') {
									linkIcon = <FaGithub />;
									linkText = 'Download source';
								}

								return (
									<span key={index}>
										<a className="project-item__text__link" href={link.url} target="_blank" rel="noreferrer">
											{linkIcon} {linkText}
										</a>
										<br />
									</span>
								);
							})
						}
					</p>}
				</div>
			</div>
		</div>
	);
}

export default ProjectItem;
